import React from "react"
//import { Link } from "gatsby"

const Footer = () => {
    
    return (

        <footer id={"footer"} className={"footer"}>
            
            <div className={"container"}>

                Tutti i diritti risevati © {new Date().getFullYear()} Invoga
                <a className="print-none" href="https://www.in-voga.com/intro-catalogo/" target="_blank" rel="noreferrer">Richiedi Accesso</a>

            </div>

        </footer>
    )
}

export default Footer
