import React from 'react'
import Helmet from 'react-helmet'

import '../styles/main.scss'

import SideBar from './sidebar'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => (

    <>
        
        <Helmet
            title="Invoga Catalogo"
            meta={[
                { name: 'description', content: 'Catalogo Invoga' },
            ]}
        />

        <SideBar right className={"sidebar"} pageWrapId={"page-wrap"} outerContainerId={"gatsby-focus-wrapper"} />

        <div id="page-wrap">

            <Header />
            
            <main id={"main"} className={"main"}>{children}</main>
            
            <Footer />

        </div>

    </>

)
  
export default Layout

