import React from "react"
//import { Link } from "gatsby"

import DarkModeToggle from './darkmodetoggle';
//import HeaderMenu from './menu/'
import Logo from './logo-brand'

const Header = () => {
    
    return (

        <header className="header">

            <div className="container">

                <h1 className="logo">
                    <Logo />
                </h1>

                <DarkModeToggle />

            </div>

        </header>
    )
}

export default Header
