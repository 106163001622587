import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const HeaderMenu = () => {

    const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
        graphql`
            query {
                allWordpressWpApiMenusMenusItems{
                    edges{
                        node{
                            id
                            name
                            items{
                                title
                                url
                                object_slug
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <nav className={"menu"}>
            <ul className={"items"}>
                {allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item) =>
                    <li className={"item"} key={item.object_slug}>
                        <Link to={item.url}>
                            {item.title}
                        </Link>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default HeaderMenu