import React from "react"
//import { Link } from "gatsby"

import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

const LogInOut = () => {

    if( !isAuthenticated() ) {
        return(
            <>
                <nav className="loginout login">
                    <a
                        href="#login"
                        onClick={e => {
                        login()
                        e.preventDefault()
                        }}
                    >
                        Log In
                    </a>
                </nav>
            </>
        )
    }

    const user = getProfile()
    
    return (
        <>
            <p className="welcome">Ciao, {user.name ? user.name : "amico"}!</p>
            <nav className="loginout logout">
                <a
                    href="#logout"
                    onClick={e => {
                    logout()
                    e.preventDefault()
                    }}
                >
                    Log Out
                </a>
            </nav>
        </>
    )
}

export default LogInOut