import React from "react";
import { slide as Menu } from "react-burger-menu";

import Logo from './logo-brand-payoff'
import DarkModeToggle from './darkmodetoggle';
import HeaderMenu from './menu';

import LogInOut from './loginout';

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>

        <h1 className={"logo"}>
            <Logo />
        </h1>

        <HeaderMenu />
            
        <DarkModeToggle />

        <LogInOut />

    </Menu>
  );
};
